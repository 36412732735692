<template>
  <div
    class="product-item"
    :class="productClasses"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <router-link
      class="main-link"
      :class="{ hovered: isHovered, }"
      :to="{ name: routeToProduct, params: { id: product.id, }, }"
    />
    <!-- Grid View -->
    <template v-if="view === 'grid'">
      <div class="header">
        <figure
          :class="{
            hovered: isHovered,
            'infos-hovered': isInfoHovered,
          }"
        >
          <img
            v-if="!isInfoHovered"
            :alt="$t('general.alt-image-produit')"
            :src="imgUrl"
            @error="imgHasError = true"
          />
          <ul
            v-else
            class="infos-supp text-medium"
          >
            <template v-if="variant === 'numerique'">
              <li>
                <span>{{ $t("produit.annee") }}</span>
                <span>{{ product.date_publication ? product.date_publication.split("-")[0] : "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.technologie") }}</span>
                <span>{{ product.nom_techno || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.version") }}</span>
                <span>{{ product.version || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.gar") }}</span>
                <span>{{ product.gar ? $t("general.oui") : $t("general.non") }}</span>
              </li>
              <li>
                <span>{{ $t("produit.editeur") }}</span>
                <span>{{ product.editeur || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.reference-editeur") }}</span>
                <span>{{ product.reference_editeur || "-" }}</span>
              </li>
            </template>
            <template v-else-if="variant === 'papier'">
              <li>
                <span>{{ $t("produit.annee") }}</span>
                <span>{{ product.annee || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.collection") }}</span>
                <span>{{ product.collections || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.auteur") }}</span>
                <span>{{ product.auteurs || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.editeur") }}</span>
                <span>{{ product.editeur || "-" }}</span>
              </li>
              <li>
                <span>{{ $t("produit.reference-editeur") }}</span>
                <span>{{ product.ref_editeur || "-" }}</span>
              </li>
            </template>
          </ul>
        </figure>
        <Badge
          v-if="!isInfoHovered && badgeStatus"
          :variant="badgeStatus"
        />
        <span
          v-if="variant !== 'fourniture'"
          class="info-button"
          @mouseover="isInfoHovered = true"
          @mouseleave="isInfoHovered = false"
        >
          <UilInfoCircle size="14" />
        </span>
      </div>

      <h1 class="title space-between">
        <!-- On met un <p> dans le h1 pour pouvoir utiliser line-clamp en CSS -->
        <p class="text-regular bold">
          {{ product.libelle }}
        </p>
        <span
          class="circle"
          :class="status"
        />
      </h1>

      <template v-if="variant !== 'fourniture'">
        <h2 class="ean text-medium">
          {{ $t("produit.ean") }} {{ product.ean }}
        </h2>
        <h2
          v-if="product.editeur"
          class="ean text-medium"
        >
          {{ product.editeur }}
        </h2>
      </template>
      <template v-else>
        <p
          v-if="product.marque"
          class="marque text-medium"
        >
          {{ $t("produit.marque") }}
          {{ /* TODO: Router-link temporaire en attendant les filtres */ }}
          <router-link
            v-if="true"
            class="link underline"
            :to="{
              name: 'search',
              query: { search: product.marque.libelle, tab: 'fournitures', },
            }"
          >
            {{ product.marque.libelle }}
          </router-link>
          <router-link
            v-else
            class="link underline"
            :to="{
              name: 'catalogues_fournitures_papeterie',
              params: { id: product.id, },
              query: { marque: product.marque.id, },
            }"
          >
            {{ product.marque.libelle }}
          </router-link>
        </p>
        <p class="reference text-medium">
          {{ $t("produit.ref") }}
          <span>{{ product.reference_interdacta }}</span>
        </p>
      </template>

      <p
        v-if="variant !== 'numerique' || product.nb_offres <= 1"
        class="prices flex-vcenter"
        :class="small ? 'text-small' : 'text-medium'"
      >
        <span
          v-if="variant === 'numerique'"
          class="before-price"
        >
          {{ $t("produit.licence-duree") }} {{ product.offre_defaut.duree_verbose }}
        </span>
        <span v-else-if="variant === 'fourniture'">{{ $t("produit.a-partir-de") }}</span>
        <strong
          v-if="prixTTC !== prixHT"
          class="all-taxes"
          v-html="$t('prix.ttc', { prix: moneyFilter(prixTTC), })"
        />
        <span
          class="duty-free"
          v-html="$t('prix.ht', { prix: moneyFilter(prixHT), })"
        />
      </p>

      <footer class="container-text-quantity space-between reverse">
        <div class="zone-input-button">
          <InputQuantity
            v-show="displayQuantity"
            :value="product.quantite"
            hide-buttons
            :status="variant === 'numerique' && product.quantite < product.offre_defaut.quantite_min
              ? 'warning'
              : 'default'
            "
            :disabled="isImpersonating"
            @change="changeQuantity(product, $event)"
          />
          <div
            v-tooltip="{
              content: $t('produit.article-n-est-pas-disponible'),
              disabled: computedIsProductAvailable,
            }"
            class="button-container"
          >
            <ButtonClassic
              v-tooltip="{
                content: tooltipContent,
                theme: 'blue',
                html: true,
                disabled: product.nb_offres > 1 || !tooltipContent,
              }"
              :class="{ 'orange-active': !isHovered && alreadyInList.length > 0, }"
              variant="special"
              :color="!isHovered ? 'primary' : 'secondary'"
              icon="left"
              :disabled="isBtnAddDisabled || isImpersonating"
              @click="checkToPanier()"
            >
              <template #left-icon>
                <component :is="icon" />
              </template>
            </ButtonClassic>
          </div>
        </div>

        <router-link
          v-if="variant === 'numerique'"
          class="link underline"
          :class="small ? 'text-small' : 'text-medium'"
          :to="{
            name: routeToProduct,
            params: { id: product.id, },
            hash: '#product_offers',
          }"
        >
          <template v-if="product.nb_offres > 1">
            {{ $t("offre.plusieurs-offres-dispo") }}
          </template>
          <template v-else-if="variant === 'numerique'">
            <template v-if="product.offre_defaut && product.offre_defaut.prescripteur">
              {{ $t("offre.offre-offerte-editeur") }}
            </template>
            <template v-else-if="product.nb_offres === 1">
              <template v-if="isHovered">
                {{ $tc("produit.licence") }}{{ "\xa0" }}:
                {{ product.offre_defaut.duree_verbose }}
                <template v-if="!compatibleAdoptant && product.categorie !== 21">
                  {{ product.offre_defaut.adoptant ? $t('offre.adoptant') : $t('offre.non-adoptant') }}
                </template>
                <br />
                {{ $tc(
                  "produit.x-licence-minimum",
                  product.offre_defaut.quantite_min,
                  { licence: product.offre_defaut.quantite_min, }
                ) }}
              </template>
              <template v-else>
                {{ $tc(
                  "produit.x-licence-minimum",
                  product.offre_defaut.quantite_min,
                  { licence: product.offre_defaut.quantite_min, }
                ) }}
              </template>
            </template>
          </template>
        </router-link>
      </footer>
    </template>
    <!-- !Grid view -->

    <!-- List view -->
    <template v-if="view === 'list'">
      <div class="product-container">
        <figure :class="{ hovered: isHovered, }">
          <img
            :src="imgUrl"
            :alt="$t('general.alt-image-produit')"
            @error="imgHasError = true"
          />
        </figure>

        <div class="infos-container">
          <div class="title space-between">
            <h1 class="text-regular bold">
              {{ product.libelle }}
            </h1>
            <div class="label-circle flex-vcenter">
              <Badge
                v-if="!isInfoHovered && badgeStatus"
                :variant="badgeStatus"
              />
              <span
                class="circle"
                :class="status"
              />
            </div>
          </div>

          <span
            v-if="variant !== 'fourniture'"
            class="ean"
          >
            {{ $t("produit.ean") }} {{ product.ean }}
          </span>
          <div class="space-between">
            <div v-if="variant === 'fourniture'">
              <p
                v-if="product.marque"
                class="marque text-medium"
              >
                {{ $t("produit.marque") }}
                {{ /* TODO: Router-link temporaire en attendant les filtres */ }}
                <router-link
                  v-if="true"
                  class="link underline"
                  :to="{
                    name: 'search',
                    query: { search: product.marque.libelle, tab: 'fournitures', },
                  }"
                >
                  {{ product.marque.libelle }}
                </router-link>
                <router-link
                  v-else
                  class="link underline"
                  :to="{
                    name: 'catalogues_fournitures_papeterie',
                    query: { marque: product.marque.id, },
                  }"
                >
                  {{ product.marque.libelle }}
                </router-link>
              </p>
              <p class="reference text-medium">
                {{ $t("produit.ref") }}
                <span>{{ product.reference_interdacta }}</span>
              </p>
            </div>
            <p
              v-if="variant === 'fourniture'"
              class="prices"
            >
              <span class="before-price">{{ $t("produit.a-partir-de") }}</span>
              <strong
                v-if="prixTTC !== prixHT"
                class="all-taxes"
                v-html="$t('prix.ttc', { prix: moneyFilter(prixTTC), })"
              />
              <span
                class="duty-free"
                v-html="$t('prix.ht', { prix: moneyFilter(prixHT), })"
              />
            </p>
          </div>

          <div class="space-between reverse">
            <div class="zone-input-button flex-vcenter">
              <router-link
                class="link"
                :class="small ? 'text-small' : 'text-medium'"
                :to="{
                  name: routeToProduct,
                  params: { id: product.id, },
                  hash: '#product_offers',
                }"
              >
                <template v-if="product.nb_offres > 1">
                  {{ $t("offre.plusieurs-offres-dispo") }}
                </template>
                <template v-else-if="variant === 'numerique'">
                  <template v-if="product.offre_defaut && product.offre_defaut.prescripteur">
                    {{ $t("offre.offre-offerte-editeur") }}
                  </template>
                  <template v-else-if="product.nb_offres === 1">
                    <template v-if="!isHovered">
                      {{ $tc('produit.licence', 1) }}{{ "\xa0" }}:
                      {{ product.offre_defaut.duree_verbose }}
                      <template v-if="!compatibleAdoptant && product.categorie !== 21">
                        {{ product.offre_defaut.adoptant ? $t('offre.adoptant') : $t('offre.non-adoptant') }}
                      </template>
                      <br />
                      {{ $tc(
                        "produit.x-licence-minimum",
                        product.offre_defaut.quantite_min,
                        { licence: product.offre_defaut.quantite_min, }
                      ) }}
                    </template>
                    <template v-else>
                      {{ $tc(
                        "produit.x-licence-minimum",
                        product.offre_defaut.quantite_min,
                        { licence: product.offre_defaut.quantite_min, }
                      ) }}
                    </template>
                  </template>
                </template>
              </router-link>
              <InputQuantity
                v-show="isHovered
                  && product.nb_offres === 1
                  && product.offre_defaut
                  && !product.offre_defaut.prescripteur"
                :value="product.quantite"
                :min="product.offre_defaut.quantite_min"
                hide-buttons
                @change="changeQuantity(product, $event)"
              />

              <div
                v-tooltip="{
                  content: $t('produit.article-n-est-pas-disponible'),
                  disabled: computedIsProductAvailable,
                }"
                class="button-container"
              >
                <ButtonClassic
                  v-tooltip="{
                    content: tooltipContent,
                    theme: 'blue',
                    html: true,
                    disabled: product.nb_offres > 1 || !tooltipContent,
                  }"
                  :class="{ 'orange-active': !isHovered && alreadyInList.length > 0, }"
                  variant="special"
                  :color="!isHovered ? 'primary' : 'secondary'"
                  icon="left"
                  :disabled="isBtnAddDisabled || isImpersonating"
                  @click="checkToPanier()"
                >
                  <template #left-icon>
                    <component :is="icon" />
                  </template>
                </ButtonClassic>
              </div>
            </div>
            <p
              v-if="variant === 'papier' || (variant === 'numerique' && product.nb_offres <= 1)"
              class="prices"
            >
              <span
                v-if="variant === 'numerique'"
                class="before-price"
              >
                {{ $t("produit.licence-duree") }} {{ product.offre_defaut.duree_verbose }}
              </span>
              <strong
                v-if="prixTTC !== prixHT"
                class="all-taxes"
                v-html="$t('prix.ttc', { prix: moneyFilter(prixTTC), })"
              />
              <span
                class="duty-free"
                v-html="$t('prix.ht', { prix: moneyFilter(prixHT), })"
              />
            </p>
          </div>
        </div>
      </div>

      <footer
        v-if="variant !== 'fourniture'"
        class="text-medium"
      >
        <ul class="infos-supp text-medium">
          <template v-if="variant === 'numerique'">
            <li>
              <span>{{ $t("produit.annee") }}</span>
              <span>{{ product.date_publication ? product.date_publication.split("-")[0] : "-" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.technologie") }}</span>
              <span>{{ product.nom_techno }}</span>
            </li>
            <li>
              <span>{{ $t("produit.version") }}</span>
              <span>{{ product.version || "-" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.gar") }}</span>
              <span>{{ product.dispo_gar ? "Oui" : "Non" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.reference-editeur") }}</span>
              <span>{{ product.reference_editeur || "-" }}</span>
            </li>
          </template>
          <template v-else-if="variant === 'papier'">
            <li>
              <span>{{ $t("produit.annee") }}</span>
              <span>{{ product.annee || "-" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.collection") }}</span>
              <span>{{ product.collections || "-" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.auteur") }}</span>
              <span>{{ product.auteurs || "-" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.editeur") }}</span>
              <span>{{ product.editeur || "-" }}</span>
            </li>
            <li>
              <span>{{ $t("produit.reference-editeur") }}</span>
              <span>{{ product.ref_editeur || "-" }}</span>
            </li>
          </template>
        </ul>
      </footer>
    </template>
  </div>
</template>

<script>
import { ButtonClassic, InputQuantity, moneyFilter } from "@lde/core_lde_vue";

import Badge from "@/components/Badge.vue";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

import { UilInfoCircle, UilArrowRight } from "@iconscout/vue-unicons";
import { UisBookmark } from "@iconscout/vue-unicons-solid";
import IconPanier from "@/components/icons/IconPanier.vue";

/**
 * Affiche les informations d'un produit (ouvrage papier, ressource numérique ou fourniture) dans le catalogue.
 */
export default {
  name: "ProductItem",
  components: {
    ButtonClassic,
    InputQuantity,
    Badge,
    UilInfoCircle,
    UisBookmark,
    UilArrowRight,
    IconPanier,
  },
  mixins: [mixinDisponibilite],
  model: {
    prop: "product",
    event: "change",
  },
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
    /**
     * Apparence des infos du produit.
     */
    view: {
      type: String,
      validator: (value) => ["grid", "list"].includes(value),
      default: "grid",
    },
    /**
     * Réduit la taille du composant.
     */
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché au changement de la quantité.
     * @param {Object} product Produit associé à la quantité.
     */
    "change",
  ],
  data() {
    return {
      isHovered: false,
      isInfoHovered: false,
      btncheckToPanierDisabled: false,
      imgHasError: false,
    };
  },
  computed: {
    ...mapGetters(["panier", "hasPerm", "compatibleAdoptant"]),
    productClasses() {
      return [
        this.view,
        {
          small: this.small,
          hovered: this.isHovered,
        },
      ];
    },
    alreadyInList() {
      if (this.panier && this.panier.lignes) {
        return this.panier.lignes.filter((ligne) => {
          if (ligne.offre?.manuel_numerique) {
            return this.product.id === ligne.offre.manuel_numerique.id;
          }
          if (ligne.offre?.fourniture) {
            return this.product.id === ligne.offre.fourniture.id;
          }
          return false;
        });
      }
      return [];
    },
    icon() {
      if (this.product.nb_offres > 1 && this.variant !== "fourniture" && !this.alreadyInList.length) {
        return UilArrowRight;
      }
      return IconPanier;
    },
    lignes() {
      return this.panier.lignes.filter(
        (ligne) => (
          this.product.id === ligne.offre?.manuel_numerique?.id || this.product.id === ligne.offre?.fourniture?.id
        ),
      );
    },
    tooltipContent() {
      if (this.alreadyInList.length) {
        let text = `<p>${this.$t("offre.ajouter-a-nouveau")}</p><ul>`;

        if (this.variant === "numerique") {
          const adoptantTexte = (ligne) => {
            if (!this.isGE && this.product.categorie !== 21) {
              return ligne.offre.adoptant ? this.$t("offre.adoptant") : this.$t("offre.non-adoptant");
            }
            return "";
          };
          this.alreadyInList.forEach((ligne) => {
            text += `<li>${ligne.offre.duree_verbose} ${adoptantTexte(ligne)}</li>`;
          });
        } else if (this.variant === "fourniture") {
          this.alreadyInList.forEach((ligne) => {
            text += `<li>${ligne.offre.fourniture.libelle}</li>`;
          });
        }

        text += `${this.$t("general.dans")}&nbsp;: ${this.panier.libelle || this.$t("liste.votre-liste")}`;
        return `${text}</ul>`;
      }
      return null;
    },
    noImgName() {
      let type = "";

      if (this.product.offre_defaut?.manuel_numerique) {
        type = "numerique";
      } else if (this.product.offre_defaut?.article) {
        type = "papier";
      } else if (this.product.offre_defaut?.fourniture) {
        type = "fourniture";
      } else {
        type = "image";
      }

      return type;
    },
    isBtnAddDisabled() {
      if (this.product.nb_offres > 1) {
        return false;
      }
      return this.btncheckToPanierDisabled
        || !this.hasPerm("add_lignedeliste")
        || (this.product.offre_defaut && this.product.offre_defaut.prescripteur)
        || !this.computedIsProductAvailable;
    },
    routeToProduct() {
      if (this.variant === "fourniture") {
        return "catalogues_fournitures_papeterie_item";
      }
      if (this.variant === "papier") {
        return "catalogues_livres_manuels_papier_item";
      }
      return "catalogues_ressources_numeriques_item";
    },
    imgUrl() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const errorImg = require(`@lde/core_lde_vue/dist/assets/media/missing_img/no_img_${this.noImgName}.svg`);

      if (this.imgHasError) {
        return errorImg;
      }

      if (this.variant === "fourniture") {
        return `https://www.interdacta.fr/photos-1/300x300/${this.product.reference_interdacta}.jpg`;
      }

      return this.product.url_couverture || errorImg;
    },
    variant() {
      if (this.product.offre_defaut?.manuel_numerique) {
        return "numerique";
      }
      if (this.product.reference_interdacta !== undefined) {
        return "fourniture";
      }
      return "papier";
    },
    badgeStatus() {
      if (
        (this.variant === "numerique" && this.product.nouveaute)
        || (this.variant !== "numerique" && this.product.offre_defaut?.nouveaute)
      ) {
        return "new";
      }
      if (this.variant === "fourniture" && this.product.offre_defaut?.promotion) {
        return "promo";
      }
      if (this.variant === "papier" && this.product.produit_remplacement) {
        return "remplace";
      }
      return null;
    },
    status() {
      return this.couleurDispo;
    },
    prixHT() {
      return this.variant === "fourniture"
        ? this.product.prix_plus_bas.ht
        : this.product.offre_defaut?.prix_ht;
    },
    prixTTC() {
      return this.variant === "fourniture"
        ? this.product.prix_plus_bas.ttc
        : this.product.offre_defaut?.prix_editeur;
    },
    displayQuantity() {
      let cond = this.isHovered && this.product.offre_defaut;

      if (this.variant === "numerique") {
        cond = cond && this.product.nb_offres === 1 && !this.product.offre_defaut?.prescripteur;
      }

      return cond;
    },
  },
  watch: {
    /**
     * Le "@blur" s'exécute avant le "@click", donc la valeur min est déjà définie.
     * Cela empêche l'utilisateur d'ajouter une quantité non désirée.
     * Ex : Quantité min = 5, il entre 3 et clique sur ajouter => On n'ajoutera pas 5 tout de suite.
     */
    "product.quantite": {
      handler() {
        setTimeout(() => {
          this.btncheckToPanierDisabled = !this.product.quantite;
        }, 100);
      },
      immediate: true,
    },
  },
  created() {
    // On a besoin de la méthode dans le tooltip, qui ne permet pas de l'appeler avec this
    window.openSelectionListModal = this.openSelectionListModal.bind(this);
  },
  mounted() {
    if (this.variant === "numerique") {
      this.$set(this.product, "quantite", this.product.offre_defaut?.quantite_min || 1);
    } else {
      this.$set(this.product, "quantite", 1);
    }
  },
  methods: {
    moneyFilter,
    /**
     * Ouvre la modale de sélection de liste.
     */
    openSelectionListModal() {
      this.$modal.show("modal_listes_selection");
    },
    /**
     * Change la quantité.
     * @param {Object} product Informations du produit.
     * @param {Number} val Nouvelle quantité.
     */
    changeQuantity(product, val) {
      this.$set(product, "quantite", val);
      this.$emit("change", product);
    },
    /**
     * Vérifie si on peut mettre l'offre en question dans le panier avant de l'envoyer au store.
     */
    checkToPanier() {
      const { quantite, offre_defaut: offreDefaut } = this.product;
      if (this.computedIsProductAvailable && (this.product.nb_offres === 1 || this.variant !== "numerique")) {
        if (!quantite) {
          this.$toast.error({ title: this.$t("toast.selection-quantite-superieur-a-x", { quantite }) });
        } else {
          this.$store.dispatch("addOffersToPanier", { lignes: [{ id_offre: offreDefaut.id, quantite }] })
            .then(() => {
              this.changeQuantity(this.product, this.variant === "numerique" ? (offreDefaut.quantite_min || 1) : 1);
            })
            .catch((error) => {
              this.$toast.error({ title: error.response?.data || this.$t("info.une-erreur-est-survenue") });
            });
        }
      } else {
        this.$router.push({ name: this.routeToProduct, params: { id: this.product.id } });
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/products/product_item.scss";
</style>
